import React from "react"
import { makeStyles, AppBar, Toolbar } from "@material-ui/core"
import LogoImg from "./logo.png"
import Navigation from "../navigation/Navigation"
import { SkipToMain } from "../Layout"

const useStyles = makeStyles(theme => ({
  logo: {
    width: "300px",
  },
  toolbar: {
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  header: {
    boxShadow: "none",
    backgroundColor: theme.palette.white,
  },
}))

export function Logo(props) {
  const classes = useStyles()
  const { customClass, hidden } = props
  return (
    <a href="#home" className={customClass} title="Home">
      <img
        src={LogoImg}
        alt="Logo of JSP multimedia.com"
        className={classes.logo}
      />
    </a>
  )
}
export default function Header() {
  const classes = useStyles()

  return (
    <AppBar position="static" className={classes.header} id="header">
      <Toolbar className={classes.toolbar}>
        <Logo />
        <SkipToMain />
        <Navigation />
      </Toolbar>
    </AppBar>
  )
}
