import React, { useDebugValue, useEffect, useRef, useState } from "react"
import ReactHtmlParser from "react-html-parser"
import { Helmet } from "react-helmet"
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  makeStyles,
} from "@material-ui/core"
import AccessibleForwardIcon from "@material-ui/icons/AccessibleForward"
import Layout from "../components/layout/Layout"
import Banner from "../components/sections/banner/Banner"
import MainServices from "../components/sections/mainServices/MainServices"
// import ContactForm from "./ContactForm"
import CarouselBlock from "../components/carousel/Carousel"
import AboutUs from "../components/about/About"
// import ContactInfo from "../../components/ContactInfo/ContactInfo"
import bg2 from "../assets/bg2.png"

const useStyles = makeStyles(theme => ({
  ...theme.customTheme,
  customHeading: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "Center",
  },
  contentLeft: {
    flex: "1 0 50%",
    alignSelf: "center",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      // padding: "0 60px 0px 60px",
      textAlign: "left",
    },
  },
  contentRight: {
    fontSize: "1.1rem",
  },

  imgIcon: {
    display: "block",
    fontSize: "3.4em",
  },
  smallText: {
    fontSize: "0.7em",
  },
  slider: {
    // marginTop: theme.spacing(1),
  },
  slide: {
    // display: "flex",
    // alignItems: "center",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
    "& img": {
      width: "155px",
      [theme.breakpoints.up("md")]: {
        width: "200px",
      },
      height: "auto",
      margin: "0 auto",
    },
  },
  customWidth: {
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
  },
}))

function FeaturedHeading() {
  const classes = useStyles()
  return (
    <Grid
      className={classes.contentLeft}
      aria-label="Front-end Development"
      aria-describedby="accessibilityHeading0"
    >
      <div className={classes.customWidth}>
        <Typography id="accessibilityHeading0" variant="h3" component="h2">
          <div style={{ fontSize: "0.7em", color: "#3f51b5" }}>
            {`We Develop `}
            <span
              style={{
                fontSize: "0.3em",
                color: "#000",
              }}
            >
              A.R.I.A
            </span>
          </div>
          {`Accessible Rich Internet Applications `}
          <Box display="flex" justifyContent="center" my={2}>
            <AccessibleForwardIcon className={classes.imgIcon} />
          </Box>
        </Typography>
      </div>
    </Grid>
  )
}

function Featured({ frontend }) {
  const classes = useStyles()
  return (
    <section id="services">
      <Box display="flex" justifyContent="center">
        <Typography
          variant="h2"
          style={{ fontSize: "2.5rem" }}
          component="span"
          align="center"
        >
          Our Services Include <span>Analysis and Consulting</span>
        </Typography>
      </Box>
      <Container>
        <Box className={classes.responsive}>
          <FeaturedHeading classes={classes} />

          <Grid className={classes.contentRight}>
            {ReactHtmlParser(frontend)}
          </Grid>
        </Box>
      </Container>
    </section>
  )
}

function ContentDevelopment({ contentDev }) {
  const classes = useStyles()
  return (
    <section aria-labelledby="contentDevelopmentHeading">
      <Container>
        <Box className={classes.responsive}>
          <Grid className={classes.contentLeft}>
            <div className={classes.customWidth}>
              <Typography
                variant="h2"
                component="h2"
                id="contentDevelopmentHeading"
              >
                Content Development
                <div style={{ fontSize: "0.7em", color: "#3f51b5" }}>
                  {" "}
                  Strategies
                </div>
              </Typography>
            </div>
          </Grid>

          <Grid className={classes.contentRight}>
            {ReactHtmlParser(contentDev.descriptionList)}
          </Grid>
        </Box>

        <Box display="flex" justifyContent="center" mt={14}>
          <Button variant="contained" color="primary" href="#contact">
            {`Contact Us`}
          </Button>
        </Box>
      </Container>
    </section>
  )
}
function SoftwareProjectManagement({ software }) {
  const classes = useStyles()
  return (
    <section aria-labelledby="software-infra-heading">
      <Container>
        <Box
          className={classes.responsive}
          style={{
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <Grid className={classes.contentLeft}>
            <Typography variant="h2" component="h2" id="software-infra-heading">
              {`Software Architecture, `}
              <div style={{ fontSize: "0.7em", color: "#3f51b5" }}>
                {"Infrastructure & Management"}
              </div>
            </Typography>
          </Grid>
          <Grid className={classes.contentRight}>
            {ReactHtmlParser(software.description)}
          </Grid>
        </Box>
      </Container>
    </section>
  )
}

function MetaTags() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>{`JSPmultimedia.com`}</title>
      <meta
        name="description"
        content="Accessible-rich / Data-driven / Internet Applications, Content Development strategies and Software Architectures."
      />
      <meta property="og:title" content="JSPmultimedia.com" />
      <meta
        property="og:description"
        content="Accessible-rich / Data-driven / Internet Applications, Content Development strategies and Software Architectures."
      />
      {/* <meta
        property="og:image"
        content={"/static/berlinbg-min-9095e08d80423725bde312e84f025208.jpg"}
      /> */}
      <meta property="og:image" content={bg2} />
      <meta property="og:url" content="https://www.jspmultimedia.com/" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default function BasicTemplate({ pageContext }) {
  const {
    pageTitle,
    pageDescription,
    pageSubTitle,
    frontend,
    contentDev,
    software,
    about,
    tag,
  } = pageContext
  const classes = useStyles()

  return (
    <Layout>
      <MetaTags />
      <Banner
        title={pageTitle}
        subTitle={pageSubTitle}
        description={pageDescription}
        tag={tag}
      />
      <Featured frontend={frontend} />
      <SoftwareProjectManagement software={software} />
      <ContentDevelopment contentDev={contentDev} />
      <CarouselBlock classes={classes} />
      <MainServices />
      <AboutUs data={about} />
    </Layout>
  )
}
