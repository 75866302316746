import React from "react"
import useKeyboardShortcuts from "./useKeyboarShortcut"

export default function KeyboardShortcut({ pressedKey, refElement, callback }) {
  const { isShiftKeyOn } = useKeyboardShortcuts(
    pressedKey,
    refElement,
    callback
  )
  return (
    isShiftKeyOn && (
      <sub className="keyboard-shortcut-tooltip">&#8679; + {pressedKey}</sub>
    )
  )
}
