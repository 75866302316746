import React from "react"
import { makeStyles } from "@material-ui/core"
// import { Link } from "gatsby"
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    color: "#000",
    textAlign: "center",
    position: "static",
    bottom: "0",
    height: "100%",
    "& P": {
      fontSize: "0.9em",
    },
  },
}))
export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <p id="copyright">
        Copyright © 2020 <span>JSPmultimedia.com </span>
        <small>
          <a href="mailto:info@jspmultimedia.com">info@jspmultimedia.com</a>
        </small>
      </p>
    </footer>
  )
}
