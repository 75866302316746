import React from "react"
import { Logo } from "../layout/header/Header"
// import PhoneIcon from "@material-ui/icons/Phone"
// import EmailIcon from "@material-ui/icons/Email"
import { Box, Typography, makeStyles, Container } from "@material-ui/core"
import ContactInfo from "../ContactInfo/ContactInfo"

const useStyles = makeStyles(theme => ({
  ...theme.customTheme,
  root: {
    background: theme.palette.backgroundLight,
    paddingTop: theme.spacing(2),
  },
  customWidth: {
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 50%",
      justifyContent: "center",
      alignSelf: "center",
      textAlign: "center",
    },
  },
  logo: {
    alignSelf: "center",
    margin: "1rem auto",
  },
}))
export default function AboutUs({ data }) {
  const classes = useStyles()

  function Description() {
    return (
      <Box style={{ textAlign: "justify" }}>
        <Typography component="p" gutterBottom>
          {data.description[1]}
        </Typography>
        <Typography component="p" gutterBottom>
          {data.description[0]}
        </Typography>
      </Box>
    )
  }
  function LogoImg() {
    return (
      <Box className={classes.customWidth}>
        <Logo customClass={classes.logo} />
      </Box>
    )
  }
  return (
    <section id="about" className={classes.root}>
      <Typography variant="h2" align="center" pb={4}>
        About Us
      </Typography>
      <Container>
        <Box className={classes.responsive} justifyContent="space-between">
          <Description />
          <LogoImg />
        </Box>
        <ContactInfo />
      </Container>
    </section>
  )
}
