import React from "react"
import Header from "./header/Header"
import theme from "../../theme/theme"
import { CssBaseline, ThemeProvider } from "@material-ui/core"
import Footer from "./footer/Footer"

export function SkipToMain() {
  return (
    <div>
      <a href="#main" className="skipLink">
        Skip to main
      </a>
    </div>
  )
}

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div id="home"></div>
      <Header />
      <main id="main">{children}</main>
      <Footer />
    </ThemeProvider>
  )
}
