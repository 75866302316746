import { useEffect, useState } from "react"

export default function useKeyboardShortcuts(
  pressedKey = "",
  ref = null,
  callback = null
) {
  const [isShiftKeyOn, setIsshitKeyOn] = useState(false)
  const handleKeyboard = props => {
    const { repeat, shiftKey, key } = props
    if (repeat) return
    shiftKey ? setIsshitKeyOn(true) : setIsshitKeyOn(false)
    if (ref && shiftKey && key === pressedKey) {
      ref.current.click()
    }
    if (callback && shiftKey && key === pressedKey) callback()
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboard)
    document.addEventListener("keyup", handleKeyboard)
    return () => {
      document.removeEventListener("keydown", handleKeyboard)
      document.removeEventListener("keyup", handleKeyboard)
    }
  }, [handleKeyboard])

  return { isShiftKeyOn }
}
