import React from "react"
import {
  Grid,
  Button,
  makeStyles,
  Box,
  Container,
  Typography,
} from "@material-ui/core"
import accessibilityImage from "../../../assets/accessibility.png"
import berlinBg from "../../../assets/berlinbg-min.jpg"

const useStyles = makeStyles(theme => ({
  banner: {
    minHeight: "40vh",
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginBottom: "1rem",
    [theme.breakpoints.up("md")]: {
      minHeight: "70vh",
    },
    "& h1": {
      fontSize: "4em",
      lineHeight: "0.8",
      // margin: 0,
      wordWrap: "break-word",
      textAlign: props => props.tag !== "front" && "center",
    },
  },
  "& p": {
    lineHeight: "1.4",
    fontSize: "1.2em",
    padding: "0 5px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "4px",
    },
  },
  big: {
    // fontSize: "1.2em",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5em",
    },
  },
  bg: {
    backgroundImage: `url(${berlinBg}) `,
    backgroundSize: "cover",
    backgroundPositionY: "-340px",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "-1",
    opacity: "0.09",
    display: "block",
  },
  textBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    textAlign: "center",
    "& > *": {
      marginBottom: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "justify",
      minHeight: props => props.tag === "front" && "315px",
    },
  },
  imageBlock: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
    },
    "& img": {
      width: "100px",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
  },
}))

export default function Banner(props) {
  const { title, subTitle, description, tag, bannerImg } = props
  const isfront = tag === "front"
  const classes = useStyles(props)

  function BannerText({ isFront }) {
    return (
      <>
        <h1>
          <span className="block">Inclusive </span>
          <span className={classes.big}>
            Digital <span className="noWrap">Experience</span>
          </span>
        </h1>
        {isFront && <Typography>{description}</Typography>}
      </>
    )
  }

  function BannerBgImg() {
    return <div className={classes.bg}></div>
  }

  function ContactButton() {
    return (
      <Box my={1}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="#contact"
        >
          {`Contact Us`}
        </Button>
      </Box>
    )
  }
  return (
    <section aria-label="Featured content" id="featured">
      <Grid className={classes.banner}>
        <Container maxWidth="lg">
          <BannerBgImg />
          <Grid container alignItems="center" justify="space-between">
            <Grid sm="12" md="5" className={classes.textBlock}>
              <BannerText isFront={isfront} />
              {isfront && <ContactButton />}
            </Grid>

            <Box className={classes.imageBlock}>
              <img
                src={bannerImg || accessibilityImage}
                alt="Digital accessibility icon"
              />
            </Box>
          </Grid>
        </Container>
      </Grid>
    </section>
  )
}
